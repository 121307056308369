import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import Image from "next/image";
import img01 from "../assets/mosaic/mosaic-01.webp";
import img02 from "../assets/mosaic/mosaic-02.webp";
import img03 from "../assets/mosaic/mosaic-03.webp";

function Mosaic() {
  const { t } = useTranslation();

  const matches = useMediaQuery("(min-width:600px)");
  if (!matches) {
    return null;
  }

  return (
    <Box
      sx={{
        width: 560,
        height: 560,
        display: "grid",
        gridTemplateColumns: "164px 118px 84px 174px",
        gridTemplateRows: "226px 48px 55px 48px 150px",
        gap: "8px",
        "& img": {
          borderTopLeftRadius: "24px",
          borderBottomRightRadius: "24px",
          objectFit: "cover",
          minWidth: "100%",
          maxWidth: "100%",
          minHeight: "100%",
          maxHeight: "100%",
          backgroundColor: "lightBlue.10",
        },
        "& div": {
          backgroundColor: "lightBlue.DEFAULT",
          borderTopLeftRadius: "24px",
          borderBottomRightRadius: "24px",
          color: "white",
          fontWeight: "bold",
          fontSize: "16px",
          textAlign: "center",
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          p: 6,
        },
        "& .alt-color": {
          backgroundColor: "lightBlue.40",
        },
      }}
    >
      <Image
        src={img02}
        alt="Three people in focusing on a computer"
        style={{
          gridArea: "1 / 1 / 3 / 4",
          objectPosition: "0% 25%",
          borderRadius: 0,
          borderTopRightRadius: "24px",
          borderBottomLeftRadius: "24px",
        }}
      />
      <Box sx={{ gridArea: "1 / 4 / 2 / 5" }}>
        <p>{t("welcome:mosaic.immediate_doc_access")}</p>
      </Box>
      <Box sx={{ gridArea: "2 / 4 / 3 / 5" }} className="alt-color"></Box>
      <Image
        src={img03}
        alt="Two scientists performing an experiment"
        style={{ gridArea: "3 / 3 / 6 / 5", objectPosition: "60% 0%" }}
      />
      <Box sx={{ gridArea: "3 / 1 / 4 / 3" }}>
        <p>{t("welcome:mosaic.self_service")}</p>
      </Box>
      <Box sx={{ gridArea: "5 / 2 / 6 / 3" }}>
        <p>{t("welcome:mosaic.247_info")}</p>
      </Box>
      <Box sx={{ gridArea: "4 / 2 / 5 / 3" }} className="alt-color"></Box>
      <Image
        src={img01}
        alt="A woman examining a crop in a field of the crop"
        style={{ gridArea: "4 / 1 / 6 / 2", objectPosition: "75% 80%" }}
      />
    </Box>
  );
}

export function About() {
  const { t } = useTranslation();

  return (
    <Container>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          py: { xs: "4rem", md: "6rem" },
          gap: { xs: "4rem", md: 0 },
        }}
      >
        <Box
          sx={{
            flex: 1,
            paddingLeft: { xs: "2rem", md: "4rem" },
            paddingRight: "2rem",
            order: { xs: 1, md: 0 },
          }}
        >
          <Box
            sx={{
              margin: "0 auto",
              display: "flex",
              flexDirection: "column",
              gap: "4rem",
            }}
          >
            <Box>
              <Typography
                variant="h2"
                sx={{
                  fontSize: { xs: "2.25rem", lg: "3.375rem" },
                  fontWeight: "bold",
                  lineHeight: 1,
                }}
              >
                {t("welcome:get_access_section.title")}
              </Typography>
              <Box
                sx={{
                  borderBottom: "10px solid #279989",
                  width: "50%",
                  marginTop: "16px",
                }}
              ></Box>
            </Box>
            <div>
              <Typography
                variant={"p"}
                sx={{ lineHeight: 1.25, fontSize: "1.25rem" }}
                dangerouslySetInnerHTML={{
                  __html: t("welcome:get_access_section.summary") ?? "",
                }}
              ></Typography>
            </div>
            <div>
              <Link href="/request-access" passHref legacyBehavior>
                <Button color="tertiary" variant="outlined">
                  {t("welcome:get_access_section.get_access_today")}
                </Button>
              </Link>
            </div>
          </Box>
        </Box>
        <Box
          sx={{
            flex: 1,
            paddingLeft: "2rem",
            paddingRight: { xs: "2rem", md: "4rem" },
            order: { xs: 0, md: 1 },
          }}
        >
          <Mosaic />
        </Box>
      </Box>
    </Container>
  );
}
