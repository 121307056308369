import { useState } from "react";

export function useEmailInvitationDialog() {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState<string | null>(null);

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false)
    setRedirectUrl(null);
  };

  const handleSubmitDialog = () => {
    if (redirectUrl) {
      window.open(redirectUrl, "_blank");
    }
    setDialogOpen(false);
  };

  return {
    dialogOpen,
    redirectUrl,
    handleOpenDialog,
    handleCloseDialog,
    handleSubmitDialog,
  };
}
