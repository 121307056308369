import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  MenuItem,
  Typography,
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import Image from "next/image";
import Link from "next/link";
import { prefixWithBasePath } from "../../lib/asset";
import { useRouter } from "next/router";
import { useState } from "react";
import { useTranslation } from "react-i18next";

type Region = {
  country: string;
  redirect: string;
  refname: string;
};

interface Props {
  open: boolean;
  onClose: () => void;
  onAgree: () => void;
  title: string;
  contentText: string;
  cancelActionText: string;
  continueActionText: string;
  regions: Region[];
  business: string;
}

export default function EmailInvitationDialog({
  open,
  onClose,
  onAgree,
  title,
  contentText,
  cancelActionText,
  continueActionText,
  regions,
  business,
}: Props) {
  const { t } = useTranslation(["welcome", "common"]);
  const [application, setApplication] = useState("");
  const [country, setCountry] = useState("");
  const [redirectUrl, setRedirectUrl] = useState("");
  const router = useRouter(); // Initialize useRouter to handle redirects

  const handleChange = (event: SelectChangeEvent) => {
    const selectedCountry = event.target.value as string;
    setCountry(selectedCountry);
    const selectedRegion = regions.find(
      (region) => region.country === selectedCountry
    );

    if (selectedRegion) {
      const selectedRefName = selectedRegion.refname;
      setApplication(selectedRefName);
      setRedirectUrl(selectedRegion.redirect);
    }
  };

  // Handle form submission and redirection logic
  const handleSubmit = () => {
    if (application && country) {
      handleAgreeDialog();
    }
  };

// Redirect the user to the specific path using Next.js router with URI encoding
const handleAgreeDialog = () => {
  if (application && country) {
    // Encode the application and country using URI encoding
    const encodedApp = encodeURIComponent(application);
    const encodedCountry = encodeURIComponent(country);

    // Redirect using the URI-encoded values
    router.push(`/unauth-home/${encodedApp}/${encodedCountry}`);
  }
};

  const renderActions = () => {
    if (redirectUrl) {
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingBottom: "40px"
          }}
        >
          <Typography gutterBottom component="div" sx={{ textAlign: "center" }}>
            {t("welcome:portal_dialog.redirect_description")}
          </Typography>
          <Link href={redirectUrl} rel="noopener noreferrer" target="_blank">
            {`${t("common:common.go_to")} ${redirectUrl}`}
          </Link>
        </Box>
      );
    } else if (country) {
      return (
        <DialogActions sx={{ justifyContent: "center" , paddingBottom: "20px" }}>
          <Button
            onClick={handleSubmit}
            disabled={application.length <= 0}
            type="button"
            variant="contained"
          >
            {continueActionText}
          </Button>
          <Button variant="text" onClick={onClose}>
            {cancelActionText}
          </Button>
        </DialogActions>
      );
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="email-dialog-title"
      aria-describedby="email-dialog-description"
    >
      <Image
        src={prefixWithBasePath("/assets/images/my-cargill-card.png")}
        alt={"Cargill Logo"}
        width={0}
        height={0}
        sizes="100vw"
        style={{ width: "100%", height: "auto" }} // optional
        priority
      />
      <DialogTitle
        sx={{ fontSize: "25px", padding: "15px", textAlign: "center" }}
      >
        {t("welcome:portal_dialog.title")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ padding: "20px" }}>
          {t("welcome:portal_dialog.description")}
        </DialogContentText>
        <FormControl fullWidth>
          <Select
            sx={{
              marginTop: "15px",
              paddingLeft: "0",
              paddingTop: "4px",
              paddingBottom: "4px",
            }}
            id="cargillRegion"
            name="cargillBusiness"
            labelId="demo-simple-select-label"
            value={country}
            onChange={handleChange}
          >
            {regions && regions.length > 0 ? (
              regions.map((item: Region, index: number) => (
                <MenuItem key={index} value={item.country}>
                  {item.country}
                </MenuItem>
              ))
            ) : (
              <MenuItem />
            )}
          </Select>
        </FormControl>
      </DialogContent>
      {renderActions()}
    </Dialog>
  );
}
