import APP_CONFIGURATIONS from "../regions";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import EmailInvitationDialog from "@components/dialog/EmailInvitationDialog";
import Typography from "@mui/material/Typography";
import { useEmailInvitationDialog } from "@hooks/useEmailInvitationDialog"; // Use the new custom hook
import { useRouter } from "next/router";
import { useState } from "react";
import { useTranslation } from "next-i18next";

type Region = {
  country: string;
  redirect: string;
  refname: string;
};

export type PortalCardProps = {
  redirection: string;
  business: string;
  cardTitle: string;
  cardContent: string;
  getAccessLabel: string;
  learnMoreLabel: string;
  title: string;
  content: string;
  image: string;
  imageAlt: string;
};

export function PortalCard({
  redirection,
  business,
  title,
  content,
  cardTitle,
  cardContent,
  image,
  imageAlt,
  getAccessLabel,
  learnMoreLabel,
  ...props
}: PortalCardProps) {
  const { t } = useTranslation();
  const {
    dialogOpen,
    handleOpenDialog,
    handleCloseDialog,
    handleSubmitDialog,
  } = useEmailInvitationDialog(); // Use the custom hook for dialog management
  const defaultBusiness = APP_CONFIGURATIONS;
  const router = useRouter();

  const [regions, setRegions] = useState<Region[]>([]);

  const openDialog = (business: string) => {
    const regions: Region[] = [];
    const selectedBusinessList = defaultBusiness.filter(
      (region) => region.business === business
    );
    selectedBusinessList.forEach((_business) => {
      const defaultredirect = _business.defaultRedirect;
      _business.countries.forEach((country) => {
        if (country.active && country.country) {
          regions.push({
            country: country.country,
            redirect:
              country.redirect && country.redirect.length > 0
                ? country.redirect
                : defaultredirect,
            refname: _business.refname,
          });
        }
      });
    });
    regions.sort((a, b) => (a.country > b.country ? 1 : -1));

    // Open the dialog with the selected business and regions
    setRegions(regions);
    handleOpenDialog(); // Pass actual data if necessary (e.g. application, country, etc.)
  };

  return (
    <>
      <Card
        data-testid={`button-${cardTitle}`}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <CardMedia
          component="img"
          alt={imageAlt}
          image={image}
          sx={{ display: "flex", justifyContent: "center" }}
        />

        <CardContent
          sx={{
            padding: "0 18px 12px 18px",
            display: "flex",
            flexDirection: "column",
            gap: "12px",
            alignSelf: "stretch",
          }}
        >
          <Typography
            variant="h4"
            sx={{ color: "Black", padding: "12px  0 12px 0" }}
          >
            {title}
          </Typography>
          <Typography variant="p">{content}</Typography>
          <Box
            sx={{
              display: "flex",
              gap: "12px",
              justifyContent: "end",
              padding: "12px",
            }}
          >
            <Button
              sx={{
                backgroundColor: "transparent",
                color: "black",
                borderRadius: "2px",
                border: "2px solid var(--Grey-Grey-20, #CCC)",
              }}
              onClick={() => {
                router.push(`/${redirection}`);
              }}
            >
              {getAccessLabel}
            </Button>
            <Button
              sx={{
                borderRadius: "2px",
                border: "var(--Light-Blue-Cargill-Light-Blue-70, #007582)",
              }}
              onClick={() => {
                openDialog(business);
              }}
            >
              {learnMoreLabel}
            </Button>
          </Box>
        </CardContent>
      </Card>

      {/* Render the EmailInvitationDialog */}
      <EmailInvitationDialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        onAgree={handleSubmitDialog}
        title={cardTitle}
        contentText={cardContent}
        cancelActionText={"cancel"}
        continueActionText={"continue"}
        regions={regions}
        business={business}
      />
    </>
  );
}
