// TODO Fetch this from the BE
import { AGRICULTURE, ANIMAL_NUTRITION, FOOD } from "../regions";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { PortalCard } from "./PortalCard";
import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useTranslation } from "next-i18next";

const NEW_CUSTOMER_ROUTE = "request-access/new-customer"
export function MyCargillPortals() {
  const { t } = useTranslation(["welcome", "common"]);

  return (
    <Container sx={{ padding: "48px" }}>
      <Box sx={{ padding: "48px" }}>
        <Typography
          variant="h2"
          sx={{
            fontSize: { xs: "2.25rem", lg: "3.375rem" },
            fontWeight: "bold",
            lineHeight: 1,
          }}
        >
          {t("welcome:portals.my_cargill_portals")}
        </Typography>
        <Box
          sx={{
            borderBottom: "10px solid #279989",
            width: "40%",
            marginTop: "16px",
          }}
        ></Box>
      </Box>
      <Box>
        <Stack
          sx={{
            gap: "24px",
            display: "inline-grid",
            gridTemplateColumns: "auto auto",
            justifyContent: "space-evenly",
          }}
        >
          <PortalCard
            redirection={NEW_CUSTOMER_ROUTE}
            business={AGRICULTURE}
            title={t("welcome:business_units.agriculture")}
            content={t("welcome:business_unit_agriculture.card_summary")}
            cardTitle={t("welcome:portal_cards.card_title")}
            cardContent={t("welcome:portal_cards.agriculture_learn_more")}
            image="https://ps2.cglcloud.com/v1/files/846e249a54e8670f03e882788f43a8bff17aadfe98e09fd1ec289925f3033c52/d4c080a2-6ce8-4f35-b211-0645c971ccee.png"
            imageAlt={"ANH"}
            getAccessLabel={`${t("common:header.get_access")} - ${t("welcome:business_units.agriculture")}`}
            learnMoreLabel={t("common:header.learn_more")}
          />

          <PortalCard
            redirection={NEW_CUSTOMER_ROUTE}
            business={ANIMAL_NUTRITION}
            title={t("welcome:business_units.animal_nutrition")}
            content={t("welcome:business_unit_animal_nutrition.card_summary")}
            cardTitle={t("welcome:portal_cards.card_title")}
            cardContent={t("welcome:portal_cards.card_content")}
            image="https://ps2.cglcloud.com/v1/files/846e249a54e8670f03e882788f43a8bff17aadfe98e09fd1ec289925f3033c52/8df36dec-a178-4617-af99-e00f72326862.png"
            imageAlt={"ANH"}
            getAccessLabel={`${t("common:header.get_access")} - ${t("welcome:business_units.animal_nutrition")}`}
            learnMoreLabel={t("common:header.learn_more")}
          />

          <PortalCard
            redirection={NEW_CUSTOMER_ROUTE}
            business={FOOD}
            title={t("welcome:business_units.food_and_beverage")}
            content={t("welcome:business_unit_food_and_beverage.card_summary")}
            cardTitle={t("welcome:portal_cards.card_title")}
            cardContent={t("welcome:portal_cards.card_content")}
            image="https://ps2.cglcloud.com/v1/files/846e249a54e8670f03e882788f43a8bff17aadfe98e09fd1ec289925f3033c52/fa9331f1-6802-4042-bbd2-65cedb7b424f.png"
            imageAlt={"FOOD"}
            getAccessLabel={`${t("common:header.get_access")} - ${t("welcome:business_units.food_and_beverage")}`}
            learnMoreLabel={t("common:header.learn_more")}
          />
        </Stack>
      </Box>
    </Container>
  );
}
export default MyCargillPortals;
