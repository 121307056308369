import dynamic from "next/dynamic";

export const LoginWidget = dynamic(
  () =>
    import("./InternalLoginWidget").then(
      (module) => module.InternalLoginWidget
    ),
  {
    ssr: false,

    loading: () => {
      return null;
    },
  }
);
