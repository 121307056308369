interface Country {
  active: string;
  redirect: string;
  country: string;
}

interface AppConfiguration {
  refname: string;
  business: string;
  defaultRedirect: string;
  countries: Country[];
  requestReasons: string[];
  restrictedEmailDomains?: string[]; // Optional, only for certain configurations
}

// Constants for business categories
export const AGRICULTURE = "agriculture";
export const FOOD = "food";
export const ANIMAL_NUTRITION = "animal_nutrition";

// Array of app configurations with explicit typing
const APP_CONFIGURATIONS: AppConfiguration[] = [
  {
    refname: "DSC_CN",
    business: AGRICULTURE,
    defaultRedirect: "https://www.cargill.com/agriculture",
    countries: [
      {
        active: "true",
        redirect: "",
        country: "Vietnam",
      },
      {
        active: "true",
        redirect: "",
        country: "China",
      },
      {
        active: "true",
        redirect: "",
        country: "Philippines",
      },
    ],
    requestReasons: [
      "View Product and Technical Documents",
      "View Deliveries",
      "View Contracts",
      "Other",
    ],
  },
  {
    refname: "DCP",
    business: AGRICULTURE,
    defaultRedirect: "https://www.cargill.com/agriculture",
    countries: [
      {
        active: "true",
        country: "United States",
        redirect: "https://www.cargillag.com/",
      },
      {
        active: "true",
        country: "Canada",
        redirect: "https://www.cargillag.ca/",
      },
    ],
    requestReasons: [
      "View Product and Technical Documents",
      "View Deliveries",
      "View Contracts",
      "Other",
    ],
  },
  {
    refname: "DIP",
    business: AGRICULTURE,
    defaultRedirect: "https://www.cargill.com/agriculture",
    countries: [
      {
        active: "true",
        redirect: "",
        country: "United Kingdom",
      },
      {
        active: "true",
        redirect: "",
        country: "Netherlands",
      },
      {
        active: "true",
        redirect: "",
        country: "Belgium",
      },
      {
        active: "true",
        redirect: "",
        country: "France",
      },
      {
        active: "true",
        redirect: "",
        country: "Spain",
      },
      {
        active: "true",
        redirect: "",
        country: "Germany",
      },
      {
        active: "true",
        redirect: "",
        country: "Italy",
      },
      {
        active: "true",
        redirect: "",
        country: "Poland",
      },
      {
        active: "true",
        redirect: "",
        country: "Hungary",
      },
      {
        active: "true",
        redirect: "",
        country: "Romania",
      },
      {
        active: "true",
        redirect: "",
        country: "Bulgaria",
      },
      {
        active: "true",
        redirect: "",
        country: "Ukraine",
      },
      {
        active: "true",
        redirect: "",
        country: "Russia",
      },
    ],
    requestReasons: [
      "View Product and Technical Documents",
      "View Deliveries",
      "View Contracts",
      "Other",
    ],
  },
  {
    refname: "GPS",
    business: AGRICULTURE,
    defaultRedirect: "https://www.cargill.com/agriculture",
    countries: [
      {
        active: "false",
        redirect: "",
        country: "Columbia",
      },
      {
        active: "false",
        redirect: "",
        country: "Peru",
      },
      {
        active: "true",
        redirect: "",
        country: "Argentina",
      },
      {
        active: "true",
        redirect: "",
        country: "Paraguay",
      },
      {
        active: "true",
        redirect: "",
        country: "Uruguay",
      },
      {
        active: "true",
        redirect: "",
        country: "Brazil",
      },
    ],
    requestReasons: [
      "View Product and Technical Documents",
      "View Deliveries",
      "View Contracts",
      "Other",
    ],
  },
  {
    refname: "CAN",
    business: ANIMAL_NUTRITION,
    defaultRedirect: "https://www.cargill.com/animal-nutrition",
    countries: [
      {
        active: "true",
        redirect: "",
        country: "United States",
      },
      {
        active: "true",
        redirect: "",
        country: "Guatemala",
      },
      {
        active: "true",
        redirect: "",
        country: "Honduras",
      },
      {
        active: "true",
        redirect: "",
        country: "Costa Rica",
      },
      {
        active: "true",
        redirect: "",
        country: "Nicaragua",
      },
      {
        active: "true",
        redirect: "",
        country: "Columbia",
      },
      {
        active: "false",
        redirect: "",
        country: "Peru",
      },
      {
        active: "true",
        redirect: "",
        country: "Brazil",
      },
      {
        active: "true",
        redirect: "",
        country: "Mexico",
      },
      {
        active: "true",
        redirect: "",
        country: "Korea",
      },
    ],
    requestReasons: [
      "View Product and Technical Documents",
      "Browse Product Catalog",
      "Place or View Orders",
      "View Reports",
      "Other",
    ],
  },
  {
    refname: "FIBI",
    business: FOOD,
    defaultRedirect: "https://www.cargill.com/food-beverage",
    countries: [
      {
        active: "true",
        redirect: "",
        country: "North America",
      },
      {
        active: "true",
        redirect: "",
        country: "South America",
      },
      {
        active: "true",
        redirect: "",
        country: "Europe, Middle East & Africa",
      },
      {
        active: "true",
        redirect: "",
        country: "Asia",
      },
    ],
    requestReasons: ["View Product and Technical Documents"],
    restrictedEmailDomains: [
      "@gmail.com",
      "@aol.com",
      "@yahoo.com",
      "@hotmail.com",
    ],
  },
];
export default APP_CONFIGURATIONS;