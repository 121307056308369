import { About } from "@components/about/About";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Head from "next/head";
import { LoginWidget } from "@components/okta/LoginWidget";
import MyCargillPortals from "@components/cargillPortals/MyCargillPortals";
import type { NextPage } from "next";
import Typography from "@mui/material/Typography";
import { prefixWithBasePath } from "../lib/asset";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { useTranslation } from "next-i18next";

const Welcome: NextPage = (): JSX.Element => {
  const { t } = useTranslation(["welcome", "common"]);

  return (
    <>
      <Head>
        <title>{`myCargill - ${t("welcome:login_section.line2")}`}</title>
        <meta
          name="description"
          content={t("welcome:login_section.line3") || undefined}
        />
      </Head>

      <WelcomeTop />
      <About />
      <MyCargillPortals />

    </>
  );
};

export default Welcome;

export const getStaticProps = async ({ locale }: any) => {
  return {
    props: {
      ...(await serverSideTranslations(locale, ["welcome", "common", "login"])),
    },
  };
};

function WelcomeTop() {
  const { t } = useTranslation(["welcome", "common"]);
  return (
    <Box
      sx={{
        backgroundImage: `url(${prefixWithBasePath(
          "/assets/images/mycargill-landing.webp"
        )})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        width: "100%",
        transition: "all",
        transitionDuration: "0.3s",
      }}
    >
      <Container
        sx={{
          padding: `2.5rem 2rem !important`,
          display: "flex",
          gap: { sm: 4, md: 10, lg: 20 },
          alignItems: {
            md: "flex-start",
            sm: "stretch",
          },
        }}
      >
        <Box
          sx={{
            flexShrink: 0,
            display: { md: "flex", xs: "none" },
          }}
        >
          <Box
            // On the home page, the size of this login widget impacts reflow.
            // so approximate the size of the box here to prevent too much reflow
            sx={{
              minWidth: "448px",
              minHeight: "527px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <LoginWidget />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            backgroundColor: {
              md: "transparent",
              xs: "rgba(255,255,255,.6)",
            },
            boxShadow: {
              md: "unset",
              xs: "1px 1px 10px #999",
            },
            p: { xs: 6, md: 0 },
            mt: { sm: 0, md: 8 },
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", gap: 6 }}>
            <Typography
              component="p"
              sx={{
                textTransform: "uppercase",
                fontSize: "1.75rem",
                lineHeight: 1,
                fontWeight: "bold",
              }}
            >
              {t("welcome:login_section.line1")}
            </Typography>

            <Typography
              variant="h1"
              sx={{
                fontSize: { xs: "2.25rem", lg: "3.375rem" },
                fontWeight: "bold",
              }}
            >
              {t("welcome:login_section.line2")}
            </Typography>

            <Typography
              variant="body1"
              sx={{
                fontSize: "1.2em",
                fontWeight: "500",
                textShadow: "1px 1px 3px #fff",
              }}
            >
              {t("welcome:login_section.line3")}
            </Typography>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
